import React from "react"
import styles from "@/common/components/Loader/Loader.module.scss"
import clsx from "clsx"

type Props = {
  isLoading?: boolean
  style?: object
  noMargin?: boolean
  className?: string
  centered?: boolean
}
export const Loader = (props: Props) => {
  if (!props.isLoading) {
    return <></>
  }

  return (
    <div className={props.className} style={{ ...props.style }}>
      <div
        className={clsx({
          [styles.Loader]: true,
          [styles.LoaderNoMargin]: props.noMargin,
          [styles.LoaderCentered]: props.centered,
        })}
      />
    </div>
  )
}
